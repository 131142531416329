import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Fetch from './Fetch';
import Datasets from './DataSets';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const BannerAlertBox = makeShortcode("BannerAlertBox");
const DownloadLink = makeShortcode("DownloadLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "stops--and-timetable-data"
    }}>{`Stops- and Timetable data`}</h1>
    <p>{`Part of our mission statement is to share our data with anyone who wants it, for free. This page lists all our timetables, and stop place data dumps which are updated daily. All data dumps have permanent URL's and come as NeTEx or GTFS datasets. NeTEx is the official format for public transport data in Norway and is the most complete in terms of available data. GTFS is a downstream format with only a limited subset of the total data, but we generate datasets for it anyway since GTFS can be easier to use and has a wider distribution among international public transport solutions. GTFS sets come in "extended" and "basic" versions.`}</p>
    <BannerAlertBox variant="info" mdxType="BannerAlertBox">
    Please be aware that certain information, important to journey planning, may
    be lacking in the GTFS files. This is, in particular, true for text-based
    information, which is not supported by GTFS.
    </BannerAlertBox>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`Category: National journey planning. License: NLOD`}</p>
    <h2 {...{
      "id": "general-information"
    }}>{`General information`}</h2>
    <p>{`The stops referred to are defined in the `}<a parentName="p" {...{
        "href": "https://stoppested.entur.org/"
      }}>{`national stop registry`}</a>{`. It's possible to view the content without an account. You can also use the geocoder or NeTEx dump to extract what you may be looking for.`}</p>
    <p>{`The stop identifiers are unique across all the datasets. Regardless of the data source.`}</p>
    <p>{`The richness of the data depends on the source data from the respective county or operator. All parties are required to submit their data in the NeTEx format, in accordance with the Norwegian NeTEX profile, but unfortunately, we're not there yet.`}</p>
    <h2 {...{
      "id": "format-specification"
    }}>{`Format specification`}</h2>
    <h3 {...{
      "id": "netex-and-norwegian-profile"
    }}>{`NeTEx and Norwegian profile.`}</h3>
    <p>{`NeTEx (Network and Timetable Exchange) is an XML-based format for sharing public transport data. It is a CEN-standard and is slated to become an EU standard for national datasets.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://netex-cen.eu/"
        }}>{`Read about NeTEx`}</a>{` (external)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://enturas.atlassian.net/wiki/spaces/PUBLIC/pages/728891481/Nordic+NeTEx+Profile"
        }}>{`Nordic NeTEx profile`}</a>{` defines all the elements, and when and how to use them.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://netex-cen.eu/?page_id=14"
        }}>{`White papers`}</a>{` (external)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/NeTEx-CEN/NeTEx"
        }}>{`XSD XML Schema`}</a>{` (external)`}</li>
    </ul>
    <h3 {...{
      "id": "gtfs"
    }}>{`GTFS`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gtfs.org/documentation/schedule/reference/"
        }}>{`Reference`}</a>{` (external)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gtfs.org/"
        }}>{`Best practice and tools`}</a>{` (external)`}</li>
    </ul>
    <h4 {...{
      "id": "time-table-data-continuously-updated"
    }}>{`Time table data (continuously updated)`}</h4>
    <br />
    <Fetch url="https://api.entur.io/timetable-public/v1/timetable-import-info/import_date" mdxType="Fetch">
    {({
        isLoading,
        data
      }) => {
        if (isLoading) {
          return null;
        } else {
          return <Datasets importDates={data} mdxType="Datasets" />;
        }
      }}
    </Fetch>
    <sub>
    * GTFS basic does not use extended route types. ** Despite a number of
    counties being merged in 2020 the affected datasets continue to be delivered
    separately.
    </sub>
    <ul>
      <li parentName="ul">{`Whenever new data is uploaded by a data owner an update of the relevant dataset `}<em parentName="li">{`and`}</em>{` the national dataset is triggered.`}</li>
      <li parentName="ul">{`Excessive downloading of files may lead to your IP being blocked. We expect that most users will have no need for more than one download per 24 hours.`}</li>
      <li parentName="ul">{`Additionally, all datasets are automatically re-validated and re-exported every night where expired data is trimmed away, retaining data for a maximum of 48 hours back in time. The original creation date, and subsequently the date of the most recent change can be found by checking the `}<inlineCode parentName="li">{`created`}</inlineCode>{` timestamp in `}<inlineCode parentName="li">{`CompositeFrame`}</inlineCode>{`. For example: `}<inlineCode parentName="li">{`created="2019-05-29T12:59:32.202"`}</inlineCode>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "stops-and-quays"
    }}>{`Stops and Quays`}</h2>
    <h3 {...{
      "id": "complete-datasets-for-norway"
    }}>{`Complete datasets for Norway`}</h3>
    <p>{`These datasets are updated every night.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NeTEx`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`GTFS`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Entire Norway (Current stops) - Latest valid version of all stops in Norway`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/Current_latest.zip" mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/Current_latest-gtfs.zip" mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Entire Norway (Current + future stops) - Current+ future versions of all stops in Norway`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/CurrentAndFuture_latest.zip" mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Entire Norway (All stop versions, incl. outdated) - Current, future and outdated versions of all stops in Norway`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/Full_latest.zip" mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "stops-and-quays-per-region--current-stops"
    }}>{`Stops and Quays per region – Current stops`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`NeTEx`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Agder`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/42_Agder_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Trøndelag`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/50_Trondelag_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Østfold`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/31_Ostfold_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Akershus`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/32_Akershus_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Buskerud`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/33_Buskerud_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Innlandet`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/34_Innlandet_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Rogaland`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/11_Rogaland_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Møre og Romsdal`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/15_More%20og%20Romsdal_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Nordland`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/18_Nordland_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Vestland`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/46_Vestland_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Oslo`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/03_Oslo_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Troms`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/55_Troms_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Finnmark`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/56_Finnmark_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Vestfold`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/39_Vestfold_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Telemark`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/40_Telemark_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Railway stations only`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><DownloadLink href="https://storage.googleapis.com/marduk-production/tiamat/RailStations_latest.zip" mdxType="DownloadLink" /></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "status-of-netex-completeness-from-providers"
    }}>{`Status of NeTEx completeness from providers`}</h2>
    <p>{`All Norwegian PTA's are sending native NeTEx from their planning system. The railway companies and some commercial PTO's are doing the same, while some are still developing this capability. The minor PTO's with only one or two lines are using AddTransit (GTFS) or the Entur-built tool Nplan (NeTEx).`}</p>
    <p>{`Planning systems in use by Entur customers capable of exporting Nordic NeTEx profile compliant data:`}</p>
    <ul>
      <li parentName="ul">{`Hastus (by Giro)`}</li>
      <li parentName="ul">{`DG BUSS (by DataGrafikk)`}</li>
      <li parentName="ul">{`Trapeze for public transport (by Trapeze)`}</li>
      <li parentName="ul">{`Train Plan (by Trapeze)`}</li>
      <li parentName="ul">{`TPSI (by HaCon)`}</li>
      <li parentName="ul">{`IVU (by IVU Traffic Technologies) `}{`*`}</li>
      <li parentName="ul">{`Nplan (by Entur) `}{`*`}</li>
      <li parentName="ul">{`AddTransit (by AddTransit)`}</li>
    </ul>
    <p>{`*`}{` in development`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      